import {WP_PAGES} from "./App";
import usePage from "./usePage";
import useGA from "./useGA";
import YoastHead from "./YoastHead";

function About() {
    const page = usePage(WP_PAGES.ABOUT);
    useGA(page);

    return (
        <>
            <YoastHead page={page} />
            <section id="top-subpage"></section>

            <section id="o-nas" className="pb-5">
                <div className="container">
                    <h2>Domek pod Tatrami</h2>

                    <p>
                        Przytulne  oraz komfortowe  miejsce, gdzie każdy odnajdzie dla siebie kącik aby wypocząć.
                        Znajdujemy się w malowniczej miejscowości o nazwie Jurgów, tuż nieopodal Białki Tatrzańskiej oraz granicy polsko-slowackiej.
                        Nasza miejscowość może pochwalić się Stacją narciarską Jurgów Ski, cudownym widokiem na Tatry Bielskie oraz najwspanialszym odcinkiem rzeki Białki.
                    </p>

                    <div className="row mx-auto" style={{ maxWidth: '800px' }}>
                        <div className="col-12 col-md-6">
                            <p>
                                Posiadamy do dyspozycji dla naszych gości dwa domki z możliwością podzielenia na cztery apartamenty (jeden dwuosobowy, a trzy pięcioosobowe).
                                Każdy z apartamentów urządzony z wielką dbałością o najmniejszy szczegół tak, aby zaspokoić oczekiwanie odwiedzających nas turystów.
                                Stworzyliśmy miejsce, w którym sami chcielibyśmy odpoczywać.
                            </p>
                            <p>
                                Do dyspozycji naszych gości jest duży, przestronny ogród ze stylową altaną, grillem, placem zabaw oraz balią ogrodową.
                                W ofercie mamy rownież salę bilardową, darta, pokój zabaw dla dzieci, gry planszowe, a także saunę.
                            </p>
                            <p>
                                Dogodna lokalizacja Domku pod Tatrami sprawia, że jesteśmy w samym środku atrakcji związanych z wypoczynkiem w górach - zimą oraz latem.
                            </p>
                            <p>
                                Zapraszamy do rezerwacji.
                            </p>
                        </div>
                        <div className="col-12 col-md-6">
                            <img src="/assets/images/attractions/IMG_4712.thumb.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;

import {Helmet} from "react-helmet";
import parse from 'html-react-parser';

export default function YoastHead({ page }) {
    if (!page) {
        return null;
    }

    return (
        <Helmet>{parse(page.yoast_head)}</Helmet>
    )
}

import Images from './Images';
import {WP_PAGES} from "./App";
import useImages from "./useImages";
import usePage from "./usePage";
import useGA from "./useGA";
import YoastHead from "./YoastHead";

function Gallery() {
    const page = usePage(WP_PAGES.GALLERY);
    const images = useImages([WP_PAGES.GALLERY]);
    useGA(page);

    return (
        <>
            <YoastHead page={page} />
            <section id="top-subpage"></section>

            <section id="galeria">
                <div className="container">
                    <h2>Galeria</h2>

                    <Images images={images} />
                </div>
            </section>
        </>
    );
}

export default Gallery;

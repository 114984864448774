import {useEffect, useState} from "react";

function Reviews() {
    const [reviews, setReviews] = useState();

    useEffect(() => {
        fetch('https://www.domekpodtatrami.pl/api/reviews.php')
            .then(response => response.json())
            .then(data => setReviews(data?.result?.reviews?.slice(0, 3)));
    }, []);

    return (
        <>
            <div id="carouselExampleCaptions" className="carousel slide d-md-none" data-bs-ride="carousel" data-bs-interval="false">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    {reviews?.map((review, key) => (
                        <div className={`carousel-item${key === 0 ? ` active` : ``}`} key={key}>
                            <div className="d-block w-100" style={{ height: '400px' }}></div>
                            <div className="carousel-caption">
                                <div className="review">
                                    <p>{review.text}</p>
                                    <small>{review.author_name}, {review.relative_time_description}</small>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div className="row justify-content-center d-none d-md-flex">
                {reviews?.map((review, key) => (
                    <div className="col-10 col-md-10 col-lg-4 mb-4" key={key}>
                        <div className="review">
                            <p>
                                {review.text}
                            </p>
                            <small>{review.author_name}, {review.relative_time_description}</small>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default Reviews;

function Carousel({ id, items }) {
    if (!items) {
        return null;
    }

    return (
        <div id={id} className="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
            <div className="carousel-indicators">
                {items.map((item, key) => (
                    <button key={key} type="button" data-bs-target={`#${id}`} data-bs-slide-to={key} className={`${key === 0 ? `active` : ``}`}></button>
                ))}
            </div>
            <div className="carousel-inner">
                {items.map((item, key) => (
                    <div className={`carousel-item${key === 0 ? ` active` : ``}`} key={key}>
                        {item.body}
                        {item.caption && (
                            <div className="carousel-caption">{item.caption}</div>
                        )}
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target={`#${id}`} data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={`#${id}`} data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Carousel;

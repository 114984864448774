import {useEffect} from "react";
import ReactGA from "react-ga4";

export default function useGA(page) {
    useEffect(() => {
        if (!page) {
            return;
        }
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: page.yoast_head_json.title });
    }, [page]);
}

import {useEffect} from "react";

export default function ReservationForm() {
    useEffect(() => {
        try { (function () { var iframe = window.document.getElementById('ra-reservation-form-v2'); function raMessageReceiver(event) { if (iframe) { if(!event.data.sender || "reservation-form" !== event.data.sender){ return; } if (event.data.height) { iframe.style.height = (event.data.height + 10) + "px"; } if (event.data.event && event.data.event.name === "widget.scrollup.requested") { try { iframe.scrollIntoView({behavior: "smooth", block: "start"}); } catch(e) {} } if(event.data.event && event.data.event.name === "reservation.submit.success"){ /*console.log(event.data.event.data.reservation); */ } if(event.data.event && event.data.event.name === "reservation.variant-search.start"){ /*console.log("variant search started"); */ } } } window.addEventListener("message", raMessageReceiver, false); function setup() { try { iframe.contentWindow.postMessage({ location: window.location.toString(), setup: { autoHeight: true } }, "*"); } catch (e) { } } setInterval(setup, 1000); iframe.addEventListener("load", setup); })(); } catch (e) { console.error(e); }
    }, []);

    return (
        <iframe id="ra-reservation-form-v2" className="w-100" style={{height: '100px'}} src="https://roomadmin.pl/widget/reservation-v2/start?fh=5900505e0e1ae5315c79e6483a195c358a5f8e98&style=%7B%22color_accent%22%3A%22%23A1195B%22%2C%22color_bg%22%3A%22%23FFFFFF%22%7D&"></iframe>
    )
}

function ApartmentCard({ number, surface, users, price, image }) {
    return (
        <div className="card">
            <a
                href="#"
                className="d-flex align-items-center justify-content-center overflow-hidden"
                style={{height: '230px'}}
                data-bs-toggle="modal"
                data-bs-target={`#modal-apartment${number}`}
            >
                <img
                    src={image?.media_details?.sizes?.medium_large?.source_url}
                    className="w-100 h-100"
                    style={{objectFit: 'cover'}} alt=""
                />
            </a>
            <div className="card-body">
                <h4 className="card-title">
                    <a href="#" data-bs-toggle="modal"
                       data-bs-target={`#modal-apartment${number}`}>Apartament {number}</a>
                </h4>
                <p className="card-text d-flex justify-content-around">
                    <span className="d-flex flex-column align-items-center">
                        <img src="assets/images/icons/blueprint.svg" alt=""/>
                        <span>{surface}m<sup>2</sup></span>
                    </span>
                    <span className="d-flex flex-column align-items-center">
                        <img src="assets/images/icons/users.svg" alt=""/>
                        {users} osób
                    </span>
                    <span className="d-flex flex-column align-items-center">
                        <img src="assets/images/icons/best-price.svg" alt=""/>
                        Od {price} zł
                    </span>
                </p>
                <button type="button" className="btn btn-brown" data-bs-toggle="modal"
                        data-bs-target={`#modal-apartment${number}`}>
                    Szczegóły
                </button>
            </div>
        </div>
    )
}

export default ApartmentCard;

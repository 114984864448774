import {useEffect, useState} from "react";

export default function useImages(parent) {
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetch('https://domekpodtatrami.pl/api/images.php?include='+parent.join(','))
            .then(response => response.json())
            .then(data => setImages(data));
    }, []);

    return images;
}

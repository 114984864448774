import {useEffect, useState} from "react";
import Carousel from "./Carousel";
import parse from "html-react-parser";

function FacebookPosts() {
    const [posts, setPosts] = useState();

    useEffect(() => {
        fetch('https://domekpodtatrami.pl/api/posts.php')
            .then(response => response.json())
            .then(data => setPosts(data));
    }, []);

    const getImage = (post) => post._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.full?.source_url;

    return (
        <>
            <div className="d-lg-none">
                <Carousel id="facebook-posts" items={posts?.map(post => ({
                    caption: parse(post.content.rendered),
                    body: (
                        <div className="d-flex align-items-center" style={{ height: '400px' }}>
                            <img src={getImage(post)} className="w-100 h-100" style={{ objectFit: 'cover' }} alt="" />
                        </div>
                    )
                }))} />
            </div>
            <div className="d-none d-lg-block">
                <div className="row">
                    {posts?.map(post => (
                        <div key={post.id} className="col-4">
                            <div className="position-relative">
                                <div className="d-flex align-items-center" style={{ height: '400px' }}>
                                    <img src={getImage(post)} className="w-100 h-100" style={{ objectFit: 'cover' }} alt="" />
                                </div>
                                <div className="carousel-caption">
                                    {parse(post.content.rendered)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default FacebookPosts;

import {WP_PAGES} from "./App";
import usePage from "./usePage";
import useGA from "./useGA";
import YoastHead from "./YoastHead";

function PrivacyPolicy() {
    const page = usePage(WP_PAGES.PRIVACY_POLICY);
    useGA(page);

    return (
        <>
            <YoastHead page={page} />
            <section id="top-subpage"></section>

            <section id="polityka-prywatnosci" className="pb-5">
                <div className="container">
                    <h2>Polityka prywatności i&nbsp;cookies</h2>

                    <p>
                        Niniejsza Polityka prywatności i cookies określa zasady przetwarzania danych osobowych oraz wykorzystywania plików cookies na stronie internetowej www.domekpodtatrami.pl. Zachęcamy do zapoznania się z niniejszym dokumentem przed korzystaniem z naszego serwisu.
                    </p>

                    <ol className="text-start mx-auto">
                        <li>
                            <b>Administrator danych osobowych</b><br />
                            Administratorem danych osobowych jest firma "Domek pod Tatrami Angelika Butas" z siedzibą w Jurgowie, ul. Jurgów 194 A, 34-532 Jurgów, zarejestrowana w 2018 r. NIP 7352644266 REGON 381979827
                        </li>
                        <li>
                            <b>Dane osobowe</b>
                            <ul>
                                <li>Podczas korzystania z naszego serwisu, możemy gromadzić pewne dane osobowe, takie jak: imię, nazwisko, adres e-mail, numer telefonu, preferencje dotyczące rezerwacji apartamentów.</li>
                                <li>Dane osobowe są gromadzone w celu umożliwienia korzystania z naszych usług, realizacji rezerwacji apartamentów, kontaktu w sprawach dotyczących rezerwacji, dostarczania informacji na temat naszych ofert.</li>
                                <li>Podanie danych osobowych jest dobrowolne, jednakże brak niektórych informacji może uniemożliwić korzystanie z niektórych funkcji serwisu.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Przetwarzanie danych osobowych</b>
                            <ul>
                                <li>Dane osobowe są przetwarzane zgodnie z obowiązującymi przepisami prawa o ochronie danych osobowych.</li>
                                <li>Dane osobowe są przetwarzane w celach związanych z realizacją umowy, świadczeniem usług oraz w celach marketingowych, takich jak dostarczanie informacji o promocjach i ofertach.</li>
                                <li>Dane osobowe nie będą udostępniane osobom trzecim bez uprzedniej zgody użytkownika, chyba że jest to konieczne do realizacji usług (np. przekazanie danych firmie obsługującej płatności).</li>
                                <li>Użytkownik ma prawo dostępu do swoich danych osobowych, ich poprawiania, usunięcia oraz wniesienia sprzeciwu wobec ich przetwarzania.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Pliki cookies</b>
                            <ul>
                                <li>Nasz serwis wykorzystuje pliki cookies w celu zapewnienia poprawnego działania strony oraz zbierania statystyk dotyczących korzystania z serwisu.</li>
                                <li>Pliki cookies są małymi plikami tekstowymi przechowywanymi na urządzeniu użytkownika. Pozwalają nam rozpoznać urządzenie użytkownika i dostosować serwis do jego preferencji.</li>
                                <li>Użytkownik może wyłączyć pliki cookies w ustawieniach swojej przeglądarki internetowej, jednakże może to wpłynąć na funkcjonalność serwisu.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Zewnętrzne serwisy</b>
                            <ul>
                                <li>Nasza strona może zawierać odnośniki do innych stron internetowych lub usług, które nie są objęte niniejszą Polityką prywatności i cookies. Zalecamy zapoznanie się z politykami prywatności tych stron trzecich przed korzystaniem z nich.</li>
                                <li>Nie ponosimy odpowiedzialności za działania i polityki prywatności innych stron internetowych lub usług.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Bezpieczeństwo danych</b>
                            <ul>
                                <li>Działamy w oparciu o odpowiednie środki techniczne i organizacyjne mające na celu ochronę danych osobowych przed nieautoryzowanym dostępem, utratą lub uszkodzeniem.</li>
                                <li>W przypadku podejrzenia naruszenia bezpieczeństwa danych osobowych, prosimy o niezwłoczne poinformowanie nas o tym fakcie.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Zmiany w Polityce prywatności i cookies</b>
                            <ul>
                                <li>Zastrzegamy sobie prawo do wprowadzania zmian w niniejszej Polityce prywatności i cookies w dowolnym czasie.</li>
                                <li>Aktualizacje Polityki będą publikowane na naszej stronie internetowej.</li>
                            </ul>
                        </li>
                        <li>
                            <b>Kontakt</b><br />
                            W przypadku pytań lub wątpliwości dotyczących Polityki prywatności i cookies, prosimy o kontakt z nami za pośrednictwem informacji kontaktowych dostępnych na naszej stronie internetowej.
                        </li>
                    </ol>

                    <p>Niniejsza Polityka prywatności i cookies wchodzi w życie od dnia 1 stycznia 2024 r.</p>
                </div>
            </section>
        </>
    );
}

export default PrivacyPolicy;

import {WP_PAGES} from "./App";
import usePage from "./usePage";
import useGA from "./useGA";
import YoastHead from "./YoastHead";

function Contact() {
    const page = usePage(WP_PAGES.CONTACT);
    useGA(page);

    return (
        <>
            <YoastHead page={page} />
            <section id="top-subpage"></section>

            <section className="page-content">
                <div className="container">
                    <h2>Kontakt</h2>

                    <p>
                        Znajdujemy się w malowniczej miejscowości o nazwie Jurgów, tuż nieopodal Białki Tatrzańskiej oraz granicy polsko-slowackiej.
                        Nasza miejscowość może pochwalić się Stacją narciarską Jurgów Ski, cudownym widokiem na Tatry Bielskie oraz najwspanialszym odcinkiem rzeki Białki.
                    </p>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-9">
                                <iframe className="map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2599.635919301766!2d20.139757615671954!3d49.34011137933752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715f7ba43b3ac73%3A0x5ee3bd156bed398f!2sDomek%20pod%20Tatrami!5e0!3m2!1spl!2spl!4v1634971274442!5m2!1spl!2spl"
                                    allowFullScreen="" loading="lazy"></iframe>
                            </div>

                            <div className="col-12 col-lg-3">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <i className="fas fa-map-marker-alt"></i>
                                        <span>
                                            "Domek Pod Tatrami" Angelika Butas<br/>
                                            Jurgów 194A, 34-532 Jurgów<br/>
                                            NIP: 7352644266
                                        </span>
                                    </li>

                                    <li className="list-group-item">
                                        <i className="fas fa-phone-alt"></i>
                                        <a href="tel:+48514418469">+48 514 418 469</a>
                                    </li>

                                    <li className="list-group-item">
                                        <i className="fas fa-envelope"></i>
                                        <a href="mailto:biuro@domekpodtatrami.pl">biuro@domekpodtatrami.pl</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Contact;

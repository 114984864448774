function Calendar() {
    return (
        <>
            <section id="top-subpage"></section>

            <section id="kalendarz">
                <div className="container">
                    <h2>Kalendarz rezerwacji</h2>

                    <iframe id="ra-calendar" className="w-100" style={{height: '500px'}} src="//roomadmin.pl/application/calendar/index?fh=5900505e0e1ae5315c79e6483a195c358a5f8e98&disableReservationButton=1&style=%7B%22color_accent%22%3A%22%23A1195B%22%2C%22color_bg%22%3A%22%23FFFFFF%22%7D"></iframe>
                </div>
            </section>
        </>
    );
}

export default Calendar;

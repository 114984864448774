import { useState } from 'react';

function CookieConsent() {
    const [value, setValue] = useState(localStorage.getItem('cookie_consent'));
    if (value) {
        return null;
    }

    const accept = () => {
        setValue('1');
        localStorage.setItem('cookie_consent', '1');
    }

    return (
        <section id="cookie-consent" className="position-fixed bg-white p-3 text-center">
            <h6><i className="fas fa-cookie-bite fa-lg text-muted" /> Używamy plików cookies</h6>
            <p><small>Pliki cookies poprawiają Twoje wrażenia<br />i ulepszają naszą witrynę</small></p>
            <button type="button" onClick={accept} className="btn btn-brown btn-sm"> Akceptuję
            </button>
        </section>
    );
}

export default CookieConsent;

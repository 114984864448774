import Images from './Images';
import ApartmentRoom from "./ApartmentRoom";

function ApartmentModal({ number, surface, users, price, images, rooms = {} }) {
    return (
        <div className="modal fade" id={`modal-apartment${number}`} tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Apartament {number}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-start">
                        <div className="equipment d-flex justify-content-around text-center mb-3">
                            <div className="d-flex flex-column align-items-center">
                                <img src="assets/images/icons/blueprint.svg" alt=""/>
                                <span>{surface}m<sup>2</sup></span>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <img src="assets/images/icons/users.svg" alt=""/>
                                Maks. {users} osób
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <img src="assets/images/icons/best-price.svg" alt=""/>
                                Od {price} zł/doba
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <img src="assets/images/icons/dishwasher.svg" alt=""/>
                                Zmywarka
                            </div>
                            {/*<div className="d-flex flex-column align-items-center">*/}
                            {/*    <img src="assets/images/icons/coffee-machine.svg" alt=""/>*/}
                            {/*    Ekspres do kawy*/}
                            {/*</div>*/}
                            <div className="d-flex flex-column align-items-center">
                                <img src="assets/images/icons/television.svg" alt=""/>
                                Telewizor
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <img src="assets/images/icons/terrace.svg" alt=""/>
                                Taras
                            </div>
                            <div className="d-flex flex-column align-items-center">
                                <img src="assets/images/icons/wifi.svg" alt=""/>
                                WiFi
                            </div>
                        </div>
                        <h5>Pomieszczenia</h5>
                        <div className="row">
                            <div className="col-6 col-lg-4">
                                <ApartmentRoom name="Salon" facilities={rooms['Salon']} />
                                <ApartmentRoom name="Sypialnia #1" facilities={rooms['Sypialnia #1']} />
                                <ApartmentRoom name="Sypialnia #2" facilities={rooms['Sypialnia #2']} />
                            </div>
                            <div className="col-6 col-lg-4">
                                <ApartmentRoom name="Aneks kuchenny" facilities={rooms['Aneks kuchenny']} />
                            </div>
                            <div className="col-12 col-lg-4">
                                <ApartmentRoom name="Łazienka" facilities={rooms['Łazienka']} />
                            </div>
                        </div>

                        <Images images={images} namespace={`apartment${number}`} width={200} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApartmentModal;

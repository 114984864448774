import {Fragment} from 'react';
import {Link} from "react-router-dom";
import {ApartmentsGrid} from "./Apartments";
import Reviews from "./Reviews";
import FacebookPosts from "./FacebookPosts";
import {WP_PAGES} from "./App";
import YoastHead from "./YoastHead";
import useGA from "./useGA";
import usePage from "./usePage";
import ReservationForm from "./ReservationForm";

function Home() {
    const page = usePage(WP_PAGES.HOME);
    useGA(page);

    return (
        <>
            <YoastHead page={page} />
            <section id="top">
                <div className="container">
                    <div className="main-title">
                        <h1>Apartamenty u&nbsp;podnóża&nbsp;Tatr</h1>

                        <p>
                            Witamy w naszym urokliwym Domku pod Tatrami, ukrytym w malowniczej miejscowości Jurgów.<br />
                            Przytulne oraz komfortowe miejsce, gdzie każdy odnajdzie dla siebie kącik aby wypocząć.Zapraszamy do odkrycia czterech wyjątkowych apartamentów, które stanowią idealną bazę do odkrywania uroków tego regionu.
                        </p>
                    </div>
                </div>
            </section>

            <section id="apartamenty" className="pb-5">
                <div className="container">
                    <h2>Apartamenty</h2>

                    <p>
                        Nasz domek oferuje różnorodność w zakresie zakwaterowania, w tym jeden intymny dwuosobowy apartament oraz trzy przestronne pięcioosobowe. Każdy z nich urządzony jest z myślą o komforcie, zapewniając zarówno prywatność, jak i dbałość o najmniejszy szczegół, tak aby zaspokoić oczekiwania odwiedzających nas gości.
                    </p>

                    <p>
                        Dla miłośników relaksu przygotowaliśmy szeroki wachlarz udogodnień. W naszym domku oferujemy saunę, w której można się odprężyć po dniu pełnym wrażeń oraz gorącą balię ogrodową, by delektować się spokojem wieczoru, podziwiając rozgwieżdżone niebo nad górami. Dla najmłodszych Gości przygotowaliśmy przyjazną salę zabaw, by ich dni były pełne radości i zabawy.
                    </p>

                    <ReservationForm />

                    <p className="mb-5">
                        <Link to="/kalendarz">Pokaż kalendarz rezerwacji</Link>
                    </p>

                    <ApartmentsGrid />

                    <p>
                        Dla tych, którzy lubią spędzać czas aktywnie, proponujemy emocjonującą grę w bilard oraz szeroką gamę zajęć na świeżym powietrzu. Nasza duża altana oraz uroczy ogród stanowią doskonałe miejsce na spotkania towarzyskie i relaksujące chwile z dala od miejskiego zgiełku.
                    </p>

                    <p>
                        Dla miłośników zimowych sportów, nasz domek jest położony blisko wyciągu narciarskiego Jurgów-Ski, zapewniającego świetne warunki do szaleństwa na stoku. Dodatkowo, dla naszych Gości przygotowaliśmy specjalną zniżkę na wypożyczenie sprzętu narciarskiego w wypożyczalni Ski-Plus, aby cieszyć się zimowymi rozrywkami jeszcze bardziej.
                    </p>

                    <p>
                        Nasze wyśmienite śniadania to prawdziwa uczta dla podniebienia - regionalne smaki i świeże składniki to nie tylko świetny początek dnia, ale także wyraz naszej troski o Państwa dobre samopoczucie i autentyczne doznania kulinarne.
                    </p>

                    <p>
                        Zapraszamy do naszego przytulnego domku pod Tatrami, gdzie kombinacja komfortu, bliskości z naturą oraz bogatej oferty atrakcji tworzy niezapomniane wspomnienia i inspiruje do odkrywania magicznych zakątków Podtatrza.
                    </p>
                </div>
            </section>

            <section id="opinie" className="pb-5">
                <h2>Opinie</h2>

                <div className="container">
                    <Reviews />

                    <p className="my-5">
                        <a href="https://www.google.pl/travel/hotels/entity/ChkIj_O039ai7_FeGg0vZy8xMWgwNG40a3pxEAE/reviews" className="btn btn-brown">
                            Zobacz wszystkie opinie naszych gości
                        </a>
                    </p>
                </div>
            </section>

            <section id="aktualnosci" className="pb-5">
                <h2>Aktualności</h2>

                <div className="container">
                    <FacebookPosts />
                    {/*<blockquote data-show-navbar="0" data-show-breadcrumbs="0" data-show-tabs="0" data-theme="custom" data-limit="3" width="100%" height="auto"*/}
                    {/*            className="postvue-page" cite="https://domekpodtatrami.postvue.pl/">*/}
                    {/*    <a href="https://domekpodtatrami.postvue.pl/">domekpodtatrami.postvue.pl</a>*/}
                    {/*</blockquote>*/}

                    <p className="my-5">
                        <a href="https://www.facebook.com/DomekpodTatrami/" className="btn btn-primary">Odwiedź nasz profil na Facebooku</a>
                    </p>
                </div>
            </section>

            <section id="kontakt">
                <h2>Kontakt</h2>

                <div className="container">
                    <p className="mb-5">
                        Znajdujemy się w malowniczej miejscowości o nazwie Jurgów, tuż nieopodal Białki Tatrzańskiej oraz granicy polsko-slowackiej.
                        Nasza miejscowość może pochwalić się Stacją narciarską Jurgów Ski, cudownym widokiem na Tatry Bielskie oraz najwspanialszym odcinkiem rzeki Białki.
                    </p>

                    <div className="row">
                        <div className="d-none d-lg-block col-lg-8 col-xl-9">
                            <iframe className="map"
                                src="https://www.google.pl/maps/embed?pb=!1m18!1m12!1m3!1d2599.635919301766!2d20.139757615671954!3d49.34011137933752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715f7ba43b3ac73%3A0x5ee3bd156bed398f!2sDomek%20pod%20Tatrami!5e0!3m2!1spl!2spl!4v1634971274442!5m2!1spl!2spl"
                                allowFullScreen="" loading="lazy"></iframe>
                        </div>

                        <div className="col-12 col-lg-4 col-xl-3">
                            <ul className="list-group list-group-flush justify-content-center">
                                <li className="list-group-item p-4" style={{ color: '#d2c4a9' }}>
                                    <i className="fas fa-map-marker-alt"></i>
                                    <span>
                                        "Domek Pod Tatrami" Angelika Butas<br/>
                                        Jurgów 194A, 34-532 Jurgów<br/>
                                        NIP: 7352644266<br/>
                                        <span className="d-inline-block d-lg-none mt-2">
                                            <a href="https://www.google.pl/maps?ll=49.340447,20.142007&z=16&t=m&hl=pl&gl=PL&mapclient=embed&cid=6837516558981216655" className="btn btn-brown" style={{ color: '#fff' }}>Pokaż mapę</a>
                                        </span>
                                    </span>
                                </li>

                                <li className="list-group-item p-4" style={{ color: '#d2c4a9' }}>
                                    <i className="fas fa-phone-alt"></i>
                                    <a href="tel:+48514418469" style={{ color: '#d2c4a9' }}>+48 514 418 469</a>
                                </li>

                                <li className="list-group-item p-4" style={{ color: '#d2c4a9' }}>
                                    <i className="fas fa-envelope"></i>
                                    <a href="mailto:biuro@domekpodtatrami.pl" style={{ color: '#d2c4a9' }}>biuro@domekpodtatrami.pl</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;

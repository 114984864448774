import {useEffect, useRef} from 'react';
import Masonry from 'masonry-layout';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Images({ images, namespace = 'gallery', width = 300 }) {
    const msnry = useRef();

    useEffect(() => {
        // setTimeout(() => {
            msnry.current = new Masonry(`.${namespace}-grid`, {
                // options
                columnWidth: width,
                itemSelector: '.grid-item',
                gutter: 16,
            });
        // }, 1000)
    }, [images, namespace, width]);

    const onLoad = () => {
        msnry.current.layout();
    };

    return (
        <div className={`${namespace}-grid`}>
            {images.map(image => (
                <a
                    key={image.id}
                    href={image.media_details?.sizes?.large?.source_url}
                    className="grid-item d-flex align-items-center justify-content-center overflow-hidden mb-3"
                    data-fancybox={`${namespace}-gallery`}
                    style={{ width: `${width}px`, height: '300px' }}
                >
                    <LazyLoadImage
                        alt=""
                        // height={image.height}
                        src={image.media_details?.sizes?.medium?.source_url} // use normal <img> attributes as props
                        onLoad={onLoad}
                        className="w-100 h-100"
                        style={{ objectFit: 'cover' }}
                        // style={{ width: `${width}px`, minHeight: '300px' }}
                        // width={image.width}
                    />
                </a>
            ))}
        </div>
    );
}

export default Images;

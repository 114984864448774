import {useEffect, useState} from "react";

export default function usePages(include) {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        fetch('https://domekpodtatrami.pl/api/pages.php?include='+include.join(','))
            .then(response => response.json())
            .then(data => setPages(data));
    }, []);

    return pages;
}

import { Link, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Gallery from './Gallery';
import {useEffect} from "react";
import Attractions from "./Attractions";
import {HashLink} from "react-router-hash-link";
import Contact from "./Contact";
import Apartments from "./Apartments";
import Calendar from "./Calendar";
import ReactGA from "react-ga4";
import PrivacyPolicy from "./PrivacyPolicy";
import CookieConsent from "./CookieConsent";
import usePages from "./usePages";
import usePage from "./usePage";

ReactGA.initialize('G-87T73DH5GH');

export const WP_PAGES = {
  HOME: 2,
  ABOUT: 166,
  APARTMENTS: 7,
  ATTRACTIONS: 62,
  GALLERY: 146,
  PRIVACY_POLICY: 3,
  CONTACT: 168,

  // Apartments
  APARTMENT_2: 9,
  APARTMENT_3: 25,
  APARTMENT_4: 39,
  APARTMENT_5: 51,

  // Atractions
  ALTANA: 78,
  BALIA: 64,
  JASKINIA_BIELANKSA: 135,
  JEZIORO_CZORSZTYNSKIE: 130,
  PLAC_ZABAW: 82,
  POKOJ_ZABAW: 75,
  RZEKA_BIALKA: 106,
  SALA_BILARDOWA: 72,
  SAUNA: 68,
  STACJA_NARCIARSKA: 87,
  SZALASY_PASTERSKIE: 99,
  SZCZYRBSKIE_JEZIORO: 140,
  SZKOLA_NARCIARSKA: 93,
  TERMY_BANIA: 120,
  TERMY_BUKOVINA: 123,
  ZAGRODA_SOLTYSOW: 113,
  ZAMEK_DUNAJEC: 126,
}

// TODO Use useRef instead
let navbar;
let menuToggle;
let bsCollapse;

function App() {
  const hideNav = () => {
    bsCollapse.hide();
  }

  // OnScroll event handler
  const onScroll = () => {
    // Get scroll value
    const scroll = document.documentElement.scrollTop
    if (scroll > 400) {
      navbar.classList.add('scrolled')
    } else {
      navbar.classList.remove('scrolled')
    }
  }

  useEffect(() => {
    navbar = document.getElementById('navbar');
    menuToggle = document.getElementById('navbarSupportedContent');
    bsCollapse = new window.bootstrap.Collapse(menuToggle, {
      toggle: false, // Makes navbar hidden by default
    });

    onScroll()
    // Use the function
    window.addEventListener('scroll', onScroll)
  }, []);

  const page = usePage(WP_PAGES.HOME);

  return (
    <>
      <style>
        {`#top, #top-subpage {
        background-image: url(${page?._embedded?.['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url})
        }`}
      </style>
      <nav id="navbar" className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container">
          <HashLink to="/#top" className="navbar-brand">
            <img src="/assets/images/logo.png?v=3" alt="Domek pod Tatrami" />
          </HashLink>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Empty element helps to keep nav in center */}
            <span></span>
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/o-nas" className="nav-link" onClick={hideNav}>O nas</Link>
              </li>
              <li className="nav-item">
                <Link to="/apartamenty" className="nav-link" onClick={hideNav}>Apartamenty</Link>
              </li>
              <li className="nav-item">
                <Link to="/atrakcje" className="nav-link" onClick={hideNav}>Atrakcje</Link>
              </li>
              <li className="nav-item">
                <Link to="/galeria" className="nav-link" onClick={hideNav}>Galeria</Link>
              </li>
              <li className="nav-item">
                <Link to="/kontakt" className="nav-link" onClick={hideNav}>Kontakt</Link>
              </li>
            </ul>
          </div>
          <ul className="nav contact">
            <li><a href="tel:+48514418469"><i className="fas fa-phone-alt"></i></a></li>
            <li><a href="mailto:biuro@domekpodtatrami.pl"><i className="fas fa-envelope"></i></a></li>
            <li><a href="https://www.facebook.com/DomekpodTatrami" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
            <li><a href="https://www.instagram.com/domekpodtatramijurgow" target="_blank"><i className="fab fa-instagram"></i></a></li>
            <li><a href="https://www.tiktok.com/@domekpodtatrami" target="_blank"><i className="fab fa-tiktok"></i></a></li>
          </ul>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/o-nas" element={<About />} />
        <Route path="/apartamenty" element={<Apartments />} />
        <Route path="/atrakcje" element={<Attractions />} />
        <Route path="/galeria" element={<Gallery />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/kalendarz" element={<Calendar />} />
        <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
      </Routes>

      <CookieConsent />

      <section id="footer">
        <div className="footer-inner">
          <div className="mb-3">
            <Link to="/o-nas">O nas</Link> | <Link to="/kontakt">Kontakt</Link> | <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
          </div>
          &copy; Domek Pod Tatrami 2024<br />
        </div>
      </section>
    </>
  );
}

export default App;

function Accordion({ id, items }) {
    return (
        <div className="accordion accordion-flush" id={id}>
            {items.map((item, key) => (
                <div key={key} className="accordion-item">
                    <h3 className="accordion-header" id={`${id}-heading-${key}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target={`#${id}-item-${key}`} aria-expanded="false" aria-controls={`${id}-item-${key}`}>
                            {item.header}
                        </button>
                    </h3>
                    <div id={`${id}-item-${key}`} className="accordion-collapse collapse" aria-labelledby={`${id}-heading-${key}`}
                         // data-bs-parent={`#${id}`}
                    >
                        <div className="accordion-body">{item.body}</div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Accordion;

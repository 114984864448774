import Images from "./Images";
import Carousel from "./Carousel";

function ImageContainer({ image }) {
    return (
        <div className="d-flex align-items-center" style={{ height: '400px' }}>
            <img src={image} className="w-100 h-100" style={{ objectFit: 'cover' }} alt="" />
        </div>
    );
}

function ResponsiveImages({ id, images }) {
    const calcWidth = () => {
        const min = 200;
        const max = 400;
        const w = Math.round(1300/images.length)-30;

        if (w > max) {
            return max;
        }

        if (w < min) {
            return min;
        }

        return w;
    }
    return (
        <>
            <div className="d-lg-none">
                <Carousel id={id} items={images.map(image => ({
                    body: <ImageContainer image={image} />
                }))} />
            </div>
            <div className="d-none d-lg-block">
                <Images namespace={id} images={images} width={calcWidth()} />
            </div>
        </>
    )
}

export default ResponsiveImages;

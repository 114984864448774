import ApartmentCard from "./ApartmentCard";
import ApartmentModal from "./ApartmentModal";
import {Link} from "react-router-dom";
import {WP_PAGES} from "./App";
import useImages from "./useImages";
import usePages from "./usePages";
import usePage from "./usePage";
import useGA from "./useGA";
import YoastHead from "./YoastHead";
import ReservationForm from "./ReservationForm";

export const APARTMENT2_ROOMS = {
    'Salon': [
        'sofa', 'stół', 'krzesła', 'telewizor'
    ],
    'Sypialnia #1': [
        'łóżko 160x200', 'łóżko 90x200', 'szafa'
    ],
    'Sypialnia #2': [
        'łóżko 140x200', 'komoda'
    ],
    'Aneks kuchenny': [
        'lodówka', 'kuchenka mikrofalowa', 'ekspres do kawy', 'czajnik elektryczny', 'płyta kuchenna',
        'przybory kuchenne', 'zmywarka', 'krzesełko dla dzieci', 'kawa, herbata, cukier, sól'
    ],
    'Łazienka': [
        'toaleta', 'prysznic', 'ręczniki', 'suszarka do włosów', 'papier toaletowy'
    ],
}

export const APARTMENT3_ROOMS = {
    ...APARTMENT2_ROOMS,
    'Sypialnia #1': [
        'dwa łóżka 90x200', 'szafa'
    ],
    'Sypialnia #2': null,
    'Aneks kuchenny': [
        'lodówka', 'kuchenka mikrofalowa', 'płyta kuchenna', 'przybory kuchenne',
        'zmywarka', 'krzesełko dla dzieci', 'kawa, herbata, cukier, sól'
    ],
}

export const APARTMENT4_ROOMS = {
    ...APARTMENT2_ROOMS,
    'Aneks kuchenny': [
        'lodówka', 'kuchenka mikrofalowa', 'ekspres do kawy', 'płyta kuchenna',
        'zmywarka', 'krzesełko dla dzieci', 'kawa, herbata, cukier, sól'
    ],
}

export const APARTMENT5_ROOMS = {
    ...APARTMENT2_ROOMS,
    'Salon': [
        'sofa', 'stolik', 'telewizor'
    ],
    'Sypialnia #1': [
        'łóżko 160x200', 'łóżko 90x200', 'komoda'
    ],
    'Sypialnia #2': [
        'dwa łóżka 90x200', 'komoda'
    ],
    'Aneks kuchenny': [
        'lodówka', 'kuchenka mikrofalowa', 'płyta kuchenna', 'przybory kuchenne',
        'zmywarka', 'krzesełko dla dzieci', 'kawa, herbata, cukier, sól'
    ],
}

export function ApartmentsGrid() {
    const pages = usePages([
        WP_PAGES.APARTMENT_2,
        WP_PAGES.APARTMENT_3,
        WP_PAGES.APARTMENT_4,
        WP_PAGES.APARTMENT_5,
    ]);

    const images = useImages([
        WP_PAGES.APARTMENT_2,
        WP_PAGES.APARTMENT_3,
        WP_PAGES.APARTMENT_4,
        WP_PAGES.APARTMENT_5,
    ]);

    return (
        <>
            <div className="row justify-content-center mb-5">
                <div className="col-10 col-md-5 col-lg-4 mx-2 mb-4">
                    <ApartmentCard number={2} surface={45} users={5} price={400}
                                   image={pages.find(page => page.id === WP_PAGES.APARTMENT_2)?._embedded?.['wp:featuredmedia'][0]} />
                    <ApartmentModal number={2} surface={45} users={5} price={400}
                                    images={images.filter(image => image.post === WP_PAGES.APARTMENT_2)}
                                    rooms={APARTMENT2_ROOMS} />
                </div>
                <div className="col-10 col-md-5 col-lg-4 mx-2 mb-4">
                    <ApartmentCard number={3} surface={30} users={2} price={300}
                                   image={pages.find(page => page.id === WP_PAGES.APARTMENT_3)?._embedded?.['wp:featuredmedia'][0]} />
                    <ApartmentModal number={3} surface={30} users={2} price={300}
                                    images={images.filter(image => image.post === WP_PAGES.APARTMENT_3)}
                                    rooms={APARTMENT3_ROOMS} />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-10 col-md-5 col-lg-4 mx-2 mb-4">
                    <ApartmentCard number={4} surface={45} users={5} price={400}
                                   image={pages.find(page => page.id === WP_PAGES.APARTMENT_4)?._embedded?.['wp:featuredmedia'][0]} />
                    <ApartmentModal number={4} surface={45} users={5} price={400}
                                    images={images.filter(image => image.post === WP_PAGES.APARTMENT_4)}
                                    rooms={APARTMENT4_ROOMS} />
                </div>
                <div className="col-10 col-md-5 col-lg-4 mx-2 mb-4">
                    <ApartmentCard number={5} surface={45} users={5} price={400}
                                   image={pages.find(page => page.id === WP_PAGES.APARTMENT_5)?._embedded?.['wp:featuredmedia'][0]} />
                    <ApartmentModal number={5} surface={45} users={5} price={400}
                                    images={images.filter(image => image.post === WP_PAGES.APARTMENT_5)}
                                    rooms={APARTMENT5_ROOMS} />
                </div>
            </div>
        </>
    )
}

function Apartments() {
    const page = usePage(WP_PAGES.APARTMENTS);
    useGA(page);

    return (
        <>
            <YoastHead page={page} />
            <section id="top-subpage"></section>

            <section id="apartamenty">
                <div className="container">
                    <h2>Apartamenty</h2>

                    <p>
                        Posiadamy do dyspozycji dla naszych gości dwa domki z możliwością podzielenia na cztery apartamenty (jeden dwuosobowy, a trzy pięcioosobowe).
                        Każdy z apartamentów urządzony z wielką dbałością o najmniejszy szczegół tak, aby zaspokoić oczekiwanie odwiedzających nas turystów.
                    </p>

                    <ReservationForm />

                    <p className="mb-5">
                        <Link to="/kalendarz">Pokaż kalendarz rezerwacji</Link>
                    </p>

                    <ApartmentsGrid />
                </div>
            </section>
        </>
    );
}

export default Apartments;

import Accordion from "./Accordion";
import ResponsiveImages from "./ResponsiveImages";
import {WP_PAGES} from "./App";
import useImages from "./useImages";
import usePage from "./usePage";
import useGA from "./useGA";
import YoastHead from "./YoastHead";

function Attractions() {
    const page = usePage(WP_PAGES.ATTRACTIONS);
    useGA(page);

    const images = useImages([
        WP_PAGES.BALIA,
        WP_PAGES.SAUNA,
        WP_PAGES.SALA_BILARDOWA,
        WP_PAGES.POKOJ_ZABAW,
        WP_PAGES.ALTANA,
        WP_PAGES.PLAC_ZABAW,
        WP_PAGES.STACJA_NARCIARSKA,
        WP_PAGES.SZKOLA_NARCIARSKA,
        WP_PAGES.SZALASY_PASTERSKIE,
        WP_PAGES.RZEKA_BIALKA,
        WP_PAGES.ZAGRODA_SOLTYSOW,
        WP_PAGES.TERMY_BANIA,
        WP_PAGES.TERMY_BUKOVINA,
        WP_PAGES.ZAMEK_DUNAJEC,
        WP_PAGES.JEZIORO_CZORSZTYNSKIE,
        WP_PAGES.JASKINIA_BIELANKSA,
        WP_PAGES.SZCZYRBSKIE_JEZIORO,
    ]);

    const ourAttractions = [
        {
            header: 'Balia ogrodowa',
            body: (
                <>
                    <p>
                        Balia ogrodowa to doskonałe miejsce do zażycia kąpieli o właściwościach relaksacyjnych i regenerujących. Zażywanie gorącej kąpieli na łonie przyrody to świetny sposób na relaks. W ten sposób całe ciało odpręża się, a umysł uspokaja. Pozytywne reakcje organizmu na taki odpoczynek wpływają ponadto na zwiększenie odporności. Kąpiele w balii poprawiają krążenie i wzmacniają pracę serca.
                    </p>
                    <ResponsiveImages id="balia-ogrodowa" images={images.filter(image => image.post === WP_PAGES.BALIA)} />
                </>
            ),
        },
        {
            header: 'Sauna fińska',
            body: (
                <>
                    <p>
                        Sauna fińska idealne miejsce na odpoczynek z przyjaciółmi czy z ukochaną osobą.
                        Zabiegi w saunie fińskiej:
                    </p>
                    <ul className="text-start">
                        <li>mają korzystny wpływ na samopoczucie</li>
                        <li>oczyszczają ciało z toksyn</li>
                        <li>poprawiają kondycję skóry</li>
                        <li>podwyższają zdolności wysiłkowe organizmu</li>
                        <li>redukują stres i zmęczenie - wspierają układ odpornościowy</li>
                        <li>zwiększają elastyczności stawów</li>
                        <li>rozluźniają mięśnie</li>
                    </ul>
                    <ResponsiveImages id="sauna" images={images.filter(image => image.post === WP_PAGES.SAUNA)} />
                </>
            ),
        },
        {
            header: 'Sala bilardowa',
            body: (
                <>
                    <p>
                        Miejsce do spotkania z przyjaciółmi lub rodziną. Sala wyposażona w profesjonalny stół bilardowy z płyty granitowej.
                    </p>
                    <ResponsiveImages id="sala-bilardowa" images={images.filter(image => image.post === WP_PAGES.SALA_BILARDOWA)} />
                </>
            ),
        },
        {
            header: 'Pokój zabaw dla dzieci',
            body: (
                <>
                    <ResponsiveImages id="pokoj-zabaw" images={images.filter(image => image.post === WP_PAGES.POKOJ_ZABAW)} />
                </>
            ),
        },
        {
            header: 'Altana z grillem',
            body: (
                <>
                    <p>
                        Duża, przestronna altana wraz z grillem może pomieścić nawet 15 osób, idealne rozwiązanie aby siąść ze znajomymi czy rodziną na świeżym powietrzu przy tym zajadając się potrawami z grilla.
                    </p>
                    <ResponsiveImages id="altana" images={images.filter(image => image.post === WP_PAGES.ALTANA)} />
                </>
            ),
        },
        {
            header: 'Plac zabaw dla dzieci',
            body: (
                <>
                    <p>
                        Drewniany domek wraz ze zjeżdżalnią i trampoliną. Dodatkowo ścianka wspinaczkowa i piaskownica
                    </p>
                    <ResponsiveImages id="plac-zabaw" images={images.filter(image => image.post === WP_PAGES.PLAC_ZABAW)} />
                </>
            ),
        },
    ];

    const attractionsNearUs = [
        {
            header: 'Stacja narciarska JURGÓW SKI',
            body: (
                <>
                    <p>
                        Oferuje 8 tras narciarskich, łącznie 4,5 km tras, zróżnicowanych pod względem trudności, nachylenia oraz długości. Na pewno każdy narciarz znajdzie tutaj coś dla siebie. Ośrodek może poszczycić się jedną z najtrudniejszych tras narciarskich na Podhalu – trasą czarną i czerwoną, które są przeznaczone dla posiadających spore umiejętności narciarzy czy też snowboardzistów. Mniej zaawansowani narciarze mają do dyspozycji trasę zieloną. Są również wyciągi dla maluszków, a także przenośnik taśmowy dla początkujących narciarzy. Stoki narciarskie są sztucznie naśnieżane, oświetlone i ratrakowane. Ośrodek dysponuje bezpłatnym parkingiem.
                    </p>
                    <ResponsiveImages id="stacja-narciarska" images={images.filter(image => image.post === WP_PAGES.STACJA_NARCIARSKA)} />
                </>
            ),
        },
        {
            header: 'Szkoła narciarska i wypożyczalnia Ski-Plus',
            body: (
                <>
                    <p>
                        Instruktorzy z wieloletnim doświadczeniem w szkoleniu z zakresu narciarstwa i snowboardu na wszystkich poziomach umiejętności z uwzględnieniem wieku, płci, oraz stopnia sprawności fizycznej. Dla naszych gości zniżka na wypożyczenie sprzętu narciarskiego czy snowboardowego
                    </p>
                    <ResponsiveImages id="szkola-narciarska" images={images.filter(image => image.post === WP_PAGES.SZKOLA_NARCIARSKA)} />
                </>
            ),
        },
        {
            header: 'Szałasy pasterskie Polana Podokólne',
            body: (
                <>
                    <p>
                        Znajdujący się w Jurgowie zespół szałasów i szop na Polanie Podokólne jest przykładem unikatowej zabudowy pasterskiej, jaka występowała na terenie Tatr i Podtatrza. Szałasy tworzą tzw. wieś letnią, nazywaną inaczej osadą biedniacką. Jest ona największą z zachowanych tego typu osad. Na Polanie Podkólne znajduje się ponad pięćdziesiąt obiektów. Usytuowane są one w czterech rzędach, rozciągających się z północy na południe. Od strony zachodniej polanę otaczają tereny rolne, oddzielając miejsca wypasu od lasu i łąk. Szałasy są bardzo rzadko wykorzystywane przez pasterzy zgodnie z ich pierwotnym przeznaczeniem, lecz stanowią niezwykłą atrakcję turystyczną.
                    </p>
                    <ResponsiveImages id="szalasy-pasterskie" images={images.filter(image => image.post === WP_PAGES.SZALASY_PASTERSKIE)} />
                </>
            ),
        },
        {
            header: 'Rzeka Białka',
            body: (
                <>
                    <p>
                        Zejście naprzeciw stacji narciarskiej Jurgów Ski, to jedna z najczystszych rzek w Polsce. Prawy dopływ Dunajca. Powstaje w Tatrach na wysokości około 1075 m n.p.m. z połączenia Rybiego Potoku spływającego z polskiej części Tatr z Białą Wodą, spływającą z słowackiej części Tatr. Następuje to u wylotu Doliny Białej Wody i Doliny Rybiego Potoku. Rzeka na większości odcinków do dzisiaj zachowała swój naturalny, górski charakter i jest nieuregulowana.
                    </p>
                    <ResponsiveImages id="rzeka-bialka" images={images.filter(image => image.post === WP_PAGES.RZEKA_BIALKA)} />
                </>
            ),
        },
        {
            header: 'Zagroda Sołtysów w Jurgowie',
            body: (
                <>
                    <p>
                        Pobudowana z drewna zagroda składa się z domu mieszkalnego i zabudowań gospodarczych w jednym ciągu i pod jednym dachem. W części mieszkalnej mamy sień, izbę i komorę. Zbudowana z okrąglaków sień – w której stoi sąsiek, naczynia bednarskie związane z pasterstwem: putnie na wodę, konewki, rajtopki na mleko, i inne przedmioty potrzebne w gospodarstwie – prowadzi do izby. W zagrodzie Sołtysów izba jest jedna – tutaj spano, przygotowywano posiłki, przyjmowano gości. Ściany izby zbudowano z płazów, a pod powałą biegnie belka sosrąb z wyrzeźbioną datą budowy i motywem rozety. Urządzenie izby jest skromne – piec, który niegdyś miał otwarte palenisko i gotowano na nim, stawiając garnki, używając jako podkładki żelaznych trójnogów, drajfusów, łóżko, półka na naczynia, ławy oraz stół, nad którym wisiały dawniej obrazy na szkle, zastąpione później oleodrukami. Ozdobą izby są makatki i powieszone nad stołem zdobione fajansowe naczynia. Rolę szafy pełnią żerdki wiszące nad łóżkiem i koło pieca.
                    </p>
                    <ResponsiveImages id="zagroda-soltysow" images={images.filter(image => image.post === WP_PAGES.ZAGRODA_SOLTYSOW)} />
                </>
            ),
        },
        {
            header: 'Termy Bania',
            body: (
                <>
                    <p>
                        Kompleks basenów, łączący zalety wodnego parku rozrywki i filozofię termalnego wypoczynku. Woda znajdująca się w basenach przez cały rok utrzymuje temperaturę na poziomie 34-38&deg;C. Zróżnicowany skład mikroelementów znajdujących się w wodzie korzystnie wpływa na zdrowie oraz kondycję fizyczną.
                    </p>
                    <ResponsiveImages id="terma-bania" images={images.filter(image => image.post === WP_PAGES.TERMY_BANIA)} />
                </>
            ),
        },
        {
            header: 'Termy BUKOVINA',
            body: (
                <>
                    <p>
                        Znane w całym regionie centrum rozrywki dla całej rodziny, ośrodkiem uzdrowiskowym i rehabilitacyjnym. Mieści się tu 20 basenów termalnych, a także zjeżdżalnie oraz pływalnie przystosowane do potrzeb dzieci.  Temperatura wody w basenach utrzymuje się w granicach 30-36&deg;C, co umożliwia kąpiele przez cały rok. Każdy, od najmłodszego do najstarszego gościa, znajdzie tu więc coś dla siebie.
                    </p>
                    <ResponsiveImages id="termy-bukovina" images={images.filter(image => image.post === WP_PAGES.TERMY_BUKOVINA)} />
                </>
            ),
        },
        {
            header: 'Zamek Dunajec',
            body: (
                <>
                    <p>
                        Średniowieczna warownia znajdująca się na prawym brzegu Zbiornika Czorsztyńskiego we wsi Niedzica-Zamek, na obszarze Polskiego Spisza lub Zamagurza.
                    </p>
                    <ResponsiveImages id="zamek-dunajec" images={images.filter(image => image.post === WP_PAGES.ZAMEK_DUNAJEC)} />
                </>
            ),
        },
        {
            header: 'Jezioro Czorsztyńskie',
            body: (
                <>
                    <p>
                        Przepiękny akwen, powstały po wybudowaniu na Dunajcu zapory przeciwpowodziowej i elektrowni wodnej. Prace trwały od 1964 do 1997 r. Jezioro Czorsztyńskie ma ok. 1100 ha powierzchni, jego głębokość dochodzi do 50 m, a maksymalna pojemność sięga 234,5 mln m³ wody. Spacery po tamie, rejsy statkami i wędkarstwo to najważniejsze atrakcje, które zbiornik dał turystom.
                    </p>
                    <ResponsiveImages id="jezioro-czorsztynskie" images={images.filter(image => image.post === WP_PAGES.JEZIORO_CZORSZTYNSKIE)} />
                </>
            ),
        },
        {
            header: 'Jaskinia Bielańska (Belianska jaskyňa)',
            body: (
                <>
                    <p>
                        Jest jedną z najczęściej odwiedzanych udostępnionych jaskiń na Słowacji. Oprócz dziwacznych form skalnych jest znana dzięki bogactwu występowania kalcytowej sintrowej ornamentyki. Pomimo faktu, że zainteresowanie atrakcjami przyrodniczymi jaskini trwa od ponad 125 lat, okoliczności jej powstawania nie są do dziś w pełni wyjaśnione.
                    </p>
                    <ResponsiveImages id="jaskinia-bielanska" images={images.filter(image => image.post === WP_PAGES.JASKINIA_BIELANKSA)} />
                </>
            ),
        },
        {
            header: 'Szczyrbskie Jezioro (Štrbské Pleso)',
            body: (
                <>
                    <p>
                        Leży u podnóża Tatr nad jeziorem o tej samej nazwie. Z tej miejscowości wypoczynkowej można zimą wjechać wyciągiem krzesełkowym Solisko Express na szereg narciarskich tras zjazdowych. Miłośnicy rekreacji na świeżym powietrzu chętnie wędrują latem po szlakach pieszych i wspinają się na wieżę widokową Tatras Tower, z której można podziwiać panoramę okolicy. Na gości czekają bezpretensjonalne restauracje oraz proste hotele i ekskluzywne ośrodki wellness z luksusowymi centrami spa.
                    </p>
                    <ResponsiveImages id="szczyrbskie-jezioro" images={images.filter(image => image.post === WP_PAGES.SZCZYRBSKIE_JEZIORO)} />
                </>
            ),
        },
    ]

    return (
        <>
            <YoastHead page={page} />
            <section id="top-subpage"></section>

            <section id="atrakcje">
                <div className="container">
                    <h2>Atrakcje u nas</h2>

                    <Accordion id="our-attractions" items={ourAttractions} />

                    <h2>Atrakcje w okolicy</h2>

                    <Accordion id="attractions-near-us" items={attractionsNearUs} />
                </div>
            </section>
        </>
    );
}

export default Attractions;

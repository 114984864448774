function ApartmentRoom({ name, facilities }) {
    if (!facilities) {
        return null;
    }

    return (
        <>
            <h6>{name}</h6>
            <ul className="list-unstyled">
                {facilities.map(facility => (
                    <li key={facility}>{facility}</li>
                ))}
            </ul>
        </>
    )
}

export default ApartmentRoom;
